<template>
  <div id="medicalDeviceRecall">
    <el-dialog
      :title="medicalDeviceRecallFormTitle"
      width="1200px"
      :visible.sync="medicalDeviceRecallDialogVisible"
      :close-on-click-modal="false"
      @close="medicalDeviceRecallDialogClose"
    >
      <el-form
        ref="medicalDeviceRecallFormRef"
        :model="medicalDeviceRecallForm"
        :rules="medicalDeviceRecallFormRules"
        label-position="right"
        label-width="150px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="召回品名" prop="recallProductName">
              <el-input v-model="medicalDeviceRecallForm.recallProductName" placeholder="请输入召回品名" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="批号" prop="batchNo">
              <el-input v-model="medicalDeviceRecallForm.batchNo" placeholder="请输入批号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="规格" prop="spec">
              <el-input v-model="medicalDeviceRecallForm.spec" placeholder="请输入规格" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="数量" prop="quantity">
              <el-input v-model="medicalDeviceRecallForm.quantity" placeholder="请输入数量" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="召回原因" prop="recallReason">
              <el-input v-model="medicalDeviceRecallForm.recallReason" placeholder="请输入召回原因" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="召回分级" prop="recallLevel">
              <el-input v-model="medicalDeviceRecallForm.recallLevel" placeholder="请输入召回分级" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="召回实施的组织" prop="organization">
              <el-input v-model="medicalDeviceRecallForm.organization" placeholder="请输入召回实施的组织" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="召回范围" prop="scope">
              <el-input v-model="medicalDeviceRecallForm.scope" placeholder="请输入召回范围" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="召回时限" prop="timeLimit">
              <el-date-picker v-model="medicalDeviceRecallForm.timeLimit" placeholder="请选择召回时限" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="日期" prop="recallDate">
              <el-date-picker v-model="medicalDeviceRecallForm.recallDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="填报部门" prop="dept">
              <el-input v-model="medicalDeviceRecallForm.dept" placeholder="请输入填报部门" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="填报人" prop="informant">
              <el-input v-model="medicalDeviceRecallForm.informant" placeholder="请输入填报人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="召回信息的公布途径与范围" prop="approach">
              <el-input
                v-model="medicalDeviceRecallForm.approach"
                placeholder="请输入召回信息的公布途径与范围"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="召回措施" prop="recallMeasures">
              <el-input
                v-model="medicalDeviceRecallForm.recallMeasures"
                placeholder="请输入召回措施"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="召回的预期效果" prop="expectedResult">
              <el-input
                v-model="medicalDeviceRecallForm.expectedResult"
                placeholder="请输入召回的预期效果"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="不良反应监测小组" prop="monitoringTeam">
              <el-input
                v-model="medicalDeviceRecallForm.monitoringTeam"
                placeholder="请输入不良反应监测小组"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="申请人" prop="applicant">
              <el-input v-model="medicalDeviceRecallForm.applicant" placeholder="请输入申请人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="日期" prop="applicationDate">
              <el-date-picker v-model="medicalDeviceRecallForm.applicationDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="管理者代表意见" prop="managerOpinion">
              <el-input
                v-model="medicalDeviceRecallForm.managerOpinion"
                placeholder="请输入管理者代表意见"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="签字" prop="managerSignature">
              <el-input v-model="medicalDeviceRecallForm.managerSignature" placeholder="请输入签字" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="日期" prop="managerSignatureDate">
              <el-date-picker v-model="medicalDeviceRecallForm.managerSignatureDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="总经理意见" prop="generalManagerOpinion">
              <el-input
                v-model="medicalDeviceRecallForm.generalManagerOpinion"
                placeholder="请输入总经理意见"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="签字" prop="generalManagerSignature">
              <el-input v-model="medicalDeviceRecallForm.generalManagerSignature" placeholder="请输入签字" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="日期" prop="generalManagerSignatureDate">
              <el-date-picker v-model="medicalDeviceRecallForm.generalManagerSignatureDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="备注" prop="remarks">
              <el-input
                v-model="medicalDeviceRecallForm.remarks"
                placeholder="请输入备注"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="medicalDeviceRecallDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="medicalDeviceRecallFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="召回品名">
        <el-input v-model="searchForm.recallProductName" placeholder="请输入召回品名" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="medicalDeviceRecallPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="recallProductName" label="召回品名" />
      <el-table-column prop="batchNo" label="批号" />
      <el-table-column prop="spec" label="规格" />
      <el-table-column prop="quantity" label="数量" />
      <el-table-column prop="approach" label="召回信息的公布途径与范围" />
      <el-table-column prop="recallReason" label="召回原因" />
      <el-table-column prop="recallLevel" label="召回分级" />
      <el-table-column prop="organization" label="召回实施的组织" />
      <el-table-column prop="scope" label="召回范围" />
      <el-table-column label="召回时限">
        <template slot-scope="scope">
          <span v-if="scope.row.timeLimit">{{ scope.row.timeLimit.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.recallDate">{{ scope.row.recallDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="dept" label="填报部门" />
      <el-table-column prop="informant" label="填报人" />
      <el-table-column prop="recallMeasures" label="召回措施" />
      <el-table-column prop="expectedResult" label="召回的预期效果" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="medicalDeviceRecallPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addMedicalDeviceRecall, deleteMedicalDeviceRecall, updateMedicalDeviceRecall, selectMedicalDeviceRecallInfo, selectMedicalDeviceRecallList } from '@/api/universal/medicalDeviceRecall'

export default {
  data () {
    return {
      medicalDeviceRecallDialogVisible: false,
      medicalDeviceRecallFormTitle: '',
      medicalDeviceRecallForm: {
        id: '',
        recallProductName: '',
        batchNo: '',
        spec: '',
        quantity: '',
        approach: '',
        recallReason: '',
        recallLevel: '',
        organization: '',
        scope: '',
        timeLimit: '',
        recallDate: '',
        dept: '',
        informant: '',
        recallMeasures: '',
        expectedResult: '',
        monitoringTeam: '',
        applicant: '',
        applicationDate: '',
        managerOpinion: '',
        managerSignature: '',
        managerSignatureDate: '',
        generalManagerOpinion: '',
        generalManagerSignature: '',
        generalManagerSignatureDate: '',
        remarks: ''
      },
      medicalDeviceRecallFormRules: {
        recallProductName: [{ required: true, message: '召回品名不能为空', trigger: ['blur', 'change']}]
      },
      medicalDeviceRecallPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        recallProductName: ''
      }
    }
  },
  created () {
    selectMedicalDeviceRecallList(this.searchForm).then(res => {
      this.medicalDeviceRecallPage = res
    })
  },
  methods: {
    medicalDeviceRecallDialogClose () {
      this.$refs.medicalDeviceRecallFormRef.resetFields()
    },
    medicalDeviceRecallFormSubmit () {
      if (this.medicalDeviceRecallFormTitle === '医疗器械召回计划 详情') {
        this.medicalDeviceRecallDialogVisible = false
        return
      }
      this.$refs.medicalDeviceRecallFormRef.validate(async valid => {
        if (valid) {
          if (this.medicalDeviceRecallFormTitle === '新增医疗器械召回计划 ') {
            await addMedicalDeviceRecall(this.medicalDeviceRecallForm)
          } else if (this.medicalDeviceRecallFormTitle === '修改医疗器械召回计划 ') {
            await updateMedicalDeviceRecall(this.medicalDeviceRecallForm)
          }
          this.medicalDeviceRecallPage = await selectMedicalDeviceRecallList(this.searchForm)
          this.medicalDeviceRecallDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.medicalDeviceRecallFormTitle = '新增医疗器械召回计划 '
      this.medicalDeviceRecallDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteMedicalDeviceRecall(row.id)
        if (this.medicalDeviceRecallPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.medicalDeviceRecallPage = await selectMedicalDeviceRecallList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.medicalDeviceRecallFormTitle = '修改医疗器械召回计划 '
      this.medicalDeviceRecallDialogVisible = true
      this.selectMedicalDeviceRecallInfoById(row.id)
    },
    handleInfo (index, row) {
      this.medicalDeviceRecallFormTitle = '医疗器械召回计划 详情'
      this.medicalDeviceRecallDialogVisible = true
      this.selectMedicalDeviceRecallInfoById(row.id)
    },
    selectMedicalDeviceRecallInfoById (id) {
      selectMedicalDeviceRecallInfo(id).then(res => {
        this.medicalDeviceRecallForm.id = res.id
        this.medicalDeviceRecallForm.recallProductName = res.recallProductName
        this.medicalDeviceRecallForm.batchNo = res.batchNo
        this.medicalDeviceRecallForm.spec = res.spec
        this.medicalDeviceRecallForm.quantity = res.quantity
        this.medicalDeviceRecallForm.approach = res.approach
        this.medicalDeviceRecallForm.recallReason = res.recallReason
        this.medicalDeviceRecallForm.recallLevel = res.recallLevel
        this.medicalDeviceRecallForm.organization = res.organization
        this.medicalDeviceRecallForm.scope = res.scope
        this.medicalDeviceRecallForm.timeLimit = res.timeLimit
        this.medicalDeviceRecallForm.recallDate = res.recallDate
        this.medicalDeviceRecallForm.dept = res.dept
        this.medicalDeviceRecallForm.informant = res.informant
        this.medicalDeviceRecallForm.recallMeasures = res.recallMeasures
        this.medicalDeviceRecallForm.expectedResult = res.expectedResult
        this.medicalDeviceRecallForm.monitoringTeam = res.monitoringTeam
        this.medicalDeviceRecallForm.applicant = res.applicant
        this.medicalDeviceRecallForm.applicationDate = res.applicationDate
        this.medicalDeviceRecallForm.managerOpinion = res.managerOpinion
        this.medicalDeviceRecallForm.managerSignature = res.managerSignature
        this.medicalDeviceRecallForm.managerSignatureDate = res.managerSignatureDate
        this.medicalDeviceRecallForm.generalManagerOpinion = res.generalManagerOpinion
        this.medicalDeviceRecallForm.generalManagerSignature = res.generalManagerSignature
        this.medicalDeviceRecallForm.generalManagerSignatureDate = res.generalManagerSignatureDate
        this.medicalDeviceRecallForm.remarks = res.remarks
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectMedicalDeviceRecallList(this.searchForm).then(res => {
        this.medicalDeviceRecallPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectMedicalDeviceRecallList(this.searchForm).then(res => {
        this.medicalDeviceRecallPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectMedicalDeviceRecallList(this.searchForm).then(res => {
        this.medicalDeviceRecallPage = res
      })
    }
  }
}
</script>

<style>
</style>
